import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import type { Game } from '../../shapes/games.types';
import { GamesSettingsService } from 'src/app/services/game-settings.service';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-game-table-cell-settings-actions',
  templateUrl: './game-table-cell-settings-actions.component.html',
  styleUrl: './game-table-cell-settings-actions.component.scss',
  imports: [MatButtonModule, MatTableModule],
})
export class GameTableCellSettingsActionsComponent {
  private readonly gamesSettingsService = inject(GamesSettingsService);

  public game = input.required<Game>();

  protected async setGameToHighestPrio(): Promise<void> {
    await this.gamesSettingsService.setHighestPriorityGame(this.game());
  }
}
