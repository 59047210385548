import { ChangeDetectionStrategy, Component, inject, input, effect, viewChild } from '@angular/core';
import { GameTableCellGeneralComponent } from '../game-table-cell-general/game-table-cell-general.component';
import { GameTableCellSettingsComponent } from '../game-table-cell-settings/game-table-cell-settings.component';
import { GameTableCellSettingsActionsComponent } from '../game-table-cell-settings-actions/game-table-cell-settings-actions.component';
import { GameTableCellSettingsActionsHeaderComponent } from '../game-table-cell-settings-actions-header/game-table-cell-settings-actions-header.component';
import { GameTableCellValuesComponent } from '../game-table-cell-values/game-table-cell-values.component';
import { IntlCurrency } from '../../utils/intlCurrency.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RtpFormat } from '../../utils/rtpFormat.pipe';
import type { Game } from '../../shapes/games.types';
import { MatPaginator } from '@angular/material/paginator';
import { DialogOrchestrationService } from 'src/app/services/dialog-orchestration.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-games-table',
  templateUrl: './games-table.component.html',
  styleUrl: './games-table.component.scss',
  imports: [
    GameTableCellGeneralComponent,
    GameTableCellSettingsComponent,
    GameTableCellSettingsActionsComponent,
    GameTableCellSettingsActionsHeaderComponent,
    GameTableCellValuesComponent,
    IntlCurrency,
    MatIconModule,
    MatSortModule,
    MatTableModule,
    RtpFormat,
  ],
})
export class GamesTableComponent {
  public gamesDataSource = input.required<MatTableDataSource<Game>>();
  public paginator = input.required<MatPaginator>();
  protected readonly dialogOrchestrationService = inject(DialogOrchestrationService);
  protected readonly gamesTableColumns = [
    'general',
    'settings',
    'settingsActions',
    'assetsUrl',
    'price',
    'rtp',
    'values',
  ];
  protected readonly sort = viewChild.required(MatSort);

  constructor() {
    effect(() => (this.gamesDataSource().sort = this.sort()));
  }
}
