import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { GamesSettingsService } from 'src/app/services/game-settings.service';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-game-table-cell-settings-actions-header',
  templateUrl: './game-table-cell-settings-actions-header.component.html',
  styleUrl: './game-table-cell-settings-actions-header.component.scss',
  imports: [MatButtonModule, MatTableModule],
})
export class GameTableCellSettingsActionsHeaderComponent {
  private readonly gamesSettingsService = inject(GamesSettingsService);

  protected async setAllGamePriosToZero(): Promise<void> {
    await this.gamesSettingsService.setGamePriosToZero();
  }
}
