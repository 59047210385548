import { Injectable, inject } from '@angular/core';
import type { Game } from '../shapes/games.types';
import { MatDialog } from '@angular/material/dialog';
import { BusyDialogComponent } from '../utils/busy.dialog';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogOrchestrationService {
  private readonly matDialogService = inject(MatDialog);

  public async showConfirmDialog(text: string): Promise<boolean> {
    const dialogComponent = await import('../common/yes-no.dialog').then(module => module.YesNoDialog);

    const result = await firstValueFrom(
      this.matDialogService.open(dialogComponent, { hasBackdrop: true, data: text }).afterClosed()
    );

    return result === true;
  }

  public async showBlockingDialog<T>(action: () => Promise<T>): Promise<T> {
    const ref = this.matDialogService.open(BusyDialogComponent, { hasBackdrop: true, disableClose: true });
    try {
      return await action();
    } finally {
      ref.close();
    }
  }

  public async showGameDetailsDialog(game: Game): Promise<void> {
    const detailsDialog = await import('../games/game-details/game-details.dialog').then(
      module => module.GameDetailsDialog
    );

    this.matDialogService.open(detailsDialog, { data: { game } });
  }
}
