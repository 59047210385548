import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogActions, MatDialogContent } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-busy-dialog',
  template: `
    <mat-dialog-content>
      <mat-progress-spinner mode="indeterminate" [diameter]="48" />
    </mat-dialog-content>
  `,
  imports: [MatDialogContent, MatProgressSpinnerModule, MatDialogActions, MatButtonModule],
})
export class BusyDialogComponent {
  constructor(private readonly dialogService: MatDialog) {}
}
