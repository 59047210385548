<form [formGroup]="gameSettingsForm" class="game-form">
  <mat-form-field class="game-state">
    <mat-select [attr.data-integrationtest-id]="integrationTestId('active')" formControlName="state">
      <mat-option [value]="gameState.active">{{ gameState.active }}</mat-option>
      <mat-option [value]="gameState.disable">{{ gameState.disable }}</mat-option>
      <mat-option [value]="gameState.shutdown">{{ gameState.shutdown }}</mat-option>
    </mat-select>
    <mat-label>Select State</mat-label>
  </mat-form-field>

  @if (isSiteVisible()) {
    <label>
      <input
        class="game-isvisible"
        type="checkbox"
        formControlName="isVisible"
        [attr.data-integrationtest-id]="integrationTestId('visible')"
        aria-label="Sichtbar"
      />
      <mat-label>Visible</mat-label>
    </label>
  }

  <label>
    <input
      [attr.data-integrationtest-id]="integrationTestId('isnew')"
      class="game-isnew"
      formControlName="isNew"
      type="checkbox"
      aria-label="Neu"
    />
    <mat-label>New</mat-label>
  </label>

  <mat-form-field class="game-priority">
    <input
      [attr.data-integrationtest-id]="integrationTestId('priority')"
      formControlName="priority"
      matInput
      type="number"
      aria-label="Priorität"
    />
    <mat-label>Priority</mat-label>
  </mat-form-field>
</form>
