import { Injectable, computed, inject, signal, type Signal } from '@angular/core';
import { GamesSearchService } from './games-search.service';
import type { Game } from '../shapes/games.types';
import type { SearchConfig } from '../shapes/games-search-config.types';

@Injectable({
  providedIn: 'root',
})
export class GamesDataService {
  private readonly gamesSearchService = inject(GamesSearchService);

  private readonly allGamesSignal = signal<Game[]>([]);
  public readonly allGames = this.allGamesSignal.asReadonly();

  public setAllGamesSignal(games: Game[]) {
    this.allGamesSignal.set(games);
  }

  public updateTargetGameInAllGamesSignal(game: Game) {
    this.allGamesSignal.update(games => {
      const index = games.findIndex(
        indexedGame => indexedGame.gameName === game.gameName && indexedGame.site === game.site
      );
      return index !== -1 ? games.toSpliced(index, 1, game) : games;
    });
  }

  private findGameNamesInSearchTerm = (gameName: string, displayName: string, searchConfig: SearchConfig) => {
    // * Colon delimiter ensures gameName/displayName strings remain discrete during includes() check
    return (
      searchConfig.game == null ||
      (gameName + ':' + displayName).toLowerCase().includes(searchConfig.game.toLowerCase())
    );
  };

  public readonly filteredGames: Signal<Game[]> = computed(() => {
    const searchConfig = this.gamesSearchService.searchConfig();
    console.info('Updating games with searchConfig: ', searchConfig);

    return this.allGamesSignal().filter(game => {
      return (
        (searchConfig.partnerId == null || game.businessPartnerId === searchConfig.partnerId) &&
        (searchConfig.site == null || game.site === searchConfig.site) &&
        this.findGameNamesInSearchTerm(game.gameName, game.displayName, searchConfig)
      );
    });
  });

  public readonly highestPrioGames: Signal<Game[]> = computed(() =>
    this.allGamesSignal()
      .filter(game => game.priority > 0)
      .toSorted((game1, game2) => game2.priority - game1.priority)
  );

  public readonly highestPrioGame: Signal<Game | undefined> = computed(() => this.highestPrioGames()[0]);
}
