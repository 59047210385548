<mat-card class="search-form">
  <mat-card-content>
    <app-games-search-mask
      (searchChanged)="gamesSearchService.updateSearchConfig($event)"
      [allGames]="gamesDataService.allGames()"
      [initialSearchConfig]="gamesSearchService.searchConfig()"
    />
  </mat-card-content>
</mat-card>

@if (currentSite()) {
  <mat-card>
    <mat-card-content>
      <app-site-actions [currentSite]="currentSite()!" [games]="filteredGames()" />
    </mat-card-content>
  </mat-card>
}

@if (loading()) {
  <mat-progress-bar mode="indeterminate" class="games-progress"></mat-progress-bar>
}

<div class="games-table">
  <app-games-table [gamesDataSource]="gamesDataSource" [paginator]="Paginator" />
</div>

<mat-paginator #Paginator [pageSizeOptions]="[10, 25, 50, 100]" pageSize="100" showFirstLastButtons />
