<table mat-table matSort matSortActive="displayName" matSortDirection="asc" [dataSource]="gamesDataSource()">
  <tr mat-header-row *matHeaderRowDef="gamesTableColumns"></tr>

  <tr mat-row *matRowDef="let game; columns: gamesTableColumns"></tr>

  <ng-container matColumnDef="general">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>General</th>

    <td mat-cell *matCellDef="let game" class="cell">
      <app-game-table-cell-general [game]="game" (click)="dialogOrchestrationService.showGameDetailsDialog(game)" />
    </td>
  </ng-container>

  <ng-container matColumnDef="settings">
    <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc"> Settings </th>

    <td mat-cell *matCellDef="let game" class="cell">
      <app-game-table-cell-settings [game]="game" />
    </td>
  </ng-container>

  <ng-container matColumnDef="settingsActions">
    <th mat-header-cell *matHeaderCellDef>
      <app-game-table-cell-settings-actions-header />
    </th>

    <td mat-cell *matCellDef="let game" class="cell">
      <app-game-table-cell-settings-actions [game]="game" />
    </td>
  </ng-container>

  <ng-container matColumnDef="values">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Values</th>

    <td mat-cell *matCellDef="let game" class="cell">
      <app-game-table-cell-values [game]="game" />
    </td>
  </ng-container>

  <ng-container matColumnDef="assetsUrl">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Assets</th>

    <td mat-cell *matCellDef="let game">
      <a href="{{ game.assetsUrl }}">
        <mat-icon>cloud_download</mat-icon>
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="price">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>

    <td mat-cell *matCellDef="let game"> {{ game.price.amount | intlCurrency: game.price.currency }} </td>
  </ng-container>

  <ng-container matColumnDef="rtp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Expected RTP</th>

    <td mat-cell *matCellDef="let game">~{{ game.rtp | rtp }}</td>
  </ng-container>
</table>
