<mat-form-field>
  <mat-label id="label-site">Site</mat-label>
  <mat-select [formControl]="form.controls.site" [attr.data-integrationtest-id]="'dropdown-site-selector'">
    <mat-option [value]="null">All sites</mat-option>

    @for (site of sites; track site) {
      <mat-option [value]="site">{{ site }}</mat-option>
    }
  </mat-select>
</mat-form-field>

<mat-form-field>
  <input
    matInput
    [formControl]="form.controls.game"
    [attr.data-integrationtest-id]="'input-game-name'"
    placeholder="Game"
  />
</mat-form-field>

<mat-form-field id="label-partner">
  <mat-label>Business Partner Id</mat-label>
  <mat-select [formControl]="form.controls.partnerId" [attr.data-integrationtest-id]="'dropdown-partner-id'">
    <mat-option [value]="null">All partners</mat-option>

    @for (partnerId of partnerIds; track partnerId) {
      <mat-option [value]="partnerId">{{ partnerId }}</mat-option>
    }
  </mat-select>
</mat-form-field>
