import { GamesDataService } from './games-data.service';
import { Injectable, inject } from '@angular/core';
import type { Game } from '../shapes/games.types';
import { GamesFetchService } from './games-fetch.service';
import type { SiteActions } from '../shapes/site-actions.types';
import { DialogOrchestrationService } from './dialog-orchestration.service';
import { GameState, type GameSettingsActions } from 'src/app/shapes/game-settings.types';

@Injectable({
  providedIn: 'root',
})
export class GamesUtilsService {
  private readonly dialogOrchestrationService = inject(DialogOrchestrationService);
  private readonly gamesFetchService = inject(GamesFetchService);
  private readonly gamesDataService = inject(GamesDataService);

  public async handleGameSettingsActions([game, gameSettingsActions]: [Game, GameSettingsActions]) {
    if (gameSettingsActions.state !== undefined) {
      switch (gameSettingsActions.state) {
        case GameState.active:
          await this.gamesFetchService.updateGameActiveState(game, 'active');
          break;

        case GameState.disable:
          await this.gamesFetchService.updateGameActiveState(game, 'disable');
          break;

        case GameState.shutdown:
          await this.gamesFetchService.updateGameActiveState(game, 'shutdown');
          break;
      }
    }

    if (gameSettingsActions.isVisible !== undefined) {
      await this.gamesFetchService.updateIsGameVisible(game, gameSettingsActions.isVisible);
    }

    if (gameSettingsActions.isNew !== undefined) {
      await this.gamesFetchService.updateIsGameNew(game, gameSettingsActions.isNew);
    }
  }

  public async handleSiteActions(siteName: string, siteActions: SiteActions) {
    if (siteActions.isSiteVisible !== undefined) {
      await this.gamesFetchService.updateIsSiteVisible(siteName, siteActions.isSiteVisible);
    }

    if (siteActions.gameActiveState !== undefined) {
      const gameActiveState: GameState = siteActions.gameActiveState ? GameState.active : GameState.disable;
      await this.updateAllTargetSiteGames(game => this.handleGameSettingsActions([game, { state: gameActiveState }]));
    }

    if (siteActions.isGameVisible !== undefined) {
      await this.updateAllTargetSiteGames(game =>
        this.handleGameSettingsActions([game, { isVisible: siteActions.isGameVisible }])
      );
    }
  }

  public async updateAllTargetSiteGames(action: (game: Game) => Promise<void>) {
    const filteredGames = this.gamesDataService.filteredGames;

    if (
      !(await this.dialogOrchestrationService.showConfirmDialog(
        `Do you really want to apply these changes to ${filteredGames().length} game/s?`
      ))
    ) {
      return;
    }

    await this.dialogOrchestrationService.showBlockingDialog(async () => {
      const results = [];
      for (const game of filteredGames()) {
        results.push(await action(game));
      }
      return results;
    });
  }
}
