import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnChanges,
  type OnInit,
  Output,
  type SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import type { Game } from '../../shapes/games.types';
import { rawValueChanges } from '../../utils/form';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export type SearchConfig = {
  game: string | null;
  site: string | null;
  partnerId: string | null;
};

// TODO: Modernize Angular APIs, add short debounce to game input field
@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-games-search-mask',
  templateUrl: './games-search-form.component.html',
  styleUrl: './games-search-form.component.scss',
  imports: [MatFormFieldModule, MatSelectModule, ReactiveFormsModule, MatInputModule],
})
export class GamesSearchFormComponent implements OnInit, OnChanges {
  @Input()
  public allGames: Game[] = [];
  @Input()
  initialSearchConfig: SearchConfig | null = null;
  @Output()
  public searchChanged = new EventEmitter<SearchConfig>();
  protected readonly form = new FormGroup({
    game: new FormControl<string | null>(null),
    site: new FormControl<string | null>(null),
    partnerId: new FormControl<string | null>(null),
  });
  protected sites: string[] = [];
  protected partnerIds: string[] = [];

  constructor() {
    rawValueChanges(this.form).pipe(takeUntilDestroyed()).subscribe(this.searchChanged);
  }

  ngOnInit() {
    if (this.initialSearchConfig) {
      this.form.setValue(this.initialSearchConfig);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('allGames' in changes) {
      this.sites = [...new Set(this.allGames.map(game => game.site))].sort();
      this.partnerIds = [...new Set(this.allGames.map(game => game.businessPartnerId))].sort();
    }
  }
}
