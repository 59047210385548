<div class="setting">
  <div class="action">
    @if (currentSite().visible) {
      <button mat-stroked-button (click)="onSiteAction({ isSiteVisible: false })">Make Site Invisible</button>
    } @else {
      <button mat-stroked-button (click)="onSiteAction({ isSiteVisible: true })">Make Site Visible</button>
    }
  </div>

  <div class="hint">Toggle the visibility on a site, but keep the game states unchanged.</div>
</div>

@if (currentSite().visible) {
  <div class="setting">
    <div class="action">
      @if (isMajorityOfSelectedGamesToggledActive()) {
        <button mat-stroked-button (click)="onSiteAction({ gameActiveState: false })">Deactivate</button>
      } @else {
        <button mat-stroked-button (click)="onSiteAction({ gameActiveState: true })">Make active</button>
      }
    </div>

    <div class="hint">Toggle the "active" state for all filtered games</div>
  </div>

  <div class="setting">
    <div class="action">
      @if (isMajorityOfSelectedGamesToggledVisible()) {
        <button mat-stroked-button (click)="onSiteAction({ isGameVisible: false })">Make Games Invisible</button>
      } @else {
        <button mat-stroked-button (click)="onSiteAction({ isGameVisible: true })">Make Games Visible</button>
      }
    </div>

    <div class="hint">Toggle the "visible" state for all filtered games</div>
  </div>
}
