import { Injectable, computed, inject, signal } from '@angular/core';
import type { Site } from '../shapes/games.types';
import { GamesSearchService } from './games-search.service';

@Injectable({
  providedIn: 'root',
})
export class GamesSiteService {
  private readonly gamesSearchService = inject(GamesSearchService);
  public readonly isSiteVisible = computed(() => (this.currentSite() ? this.currentSite()?.visible : false));
  private readonly allSitesSignal = signal<Site[]>([]);
  public readonly currentSite = computed(() => {
    const sites = this.allSitesSignal();
    const search = this.gamesSearchService.searchConfig();
    return search.site ? sites.find(site => site.name === search.site) : undefined;
  });

  public setAllSitesSignal(sites: Site[]) {
    this.allSitesSignal.set(sites);
  }

  public updateIsVisibleAllSitesSignal(siteName: string, visible: boolean) {
    this.allSitesSignal.update(sites => {
      const index = sites.findIndex(site => site.name === siteName);
      return sites.toSpliced(index, 1, { name: siteName, visible });
    });
  }
}
