<div class="column column-icon">
  <img [ngSrc]="game.gameUrl + game.extraConfig.images?.thumb?.de_DE" alt="Game Table Thumbnail" fill />
</div>

<div class="column column-general">
  <div>
    <div class="game-name">{{ game.displayName }}</div>
    <small>{{ game.gameName }}</small>
  </div>

  <div>
    <div class="game-site">{{ game.site }}</div>
  </div>
</div>
