import { computed, inject, Injectable, signal } from '@angular/core';
import { type SearchConfig } from '../shapes/games-search-config.types';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class GamesSearchService {
  private readonly router = inject(Router);
  private readonly queryParamsSnapshotSignal = signal(inject(ActivatedRoute).snapshot.queryParams);
  public readonly queryParamsSnapshot = this.queryParamsSnapshotSignal.asReadonly();
  private readonly queryToConfig = computed(() => {
    return {
      game: this.queryParamsSnapshot().game || null,
      site: this.queryParamsSnapshot().site || null,
      partnerId: this.queryParamsSnapshot().partnerId || null,
    };
  });
  private readonly searchConfigSignal = signal(this.queryToConfig(), { equal: this.searchConfigEquals });
  readonly searchConfig = this.searchConfigSignal.asReadonly();

  private async updateConfigInUrl(searchConfig: SearchConfig) {
    await this.router.navigate([], {
      queryParams: { game: searchConfig.game, site: searchConfig.site, partnerId: searchConfig.partnerId },
      replaceUrl: true,
    });
  }

  private searchConfigEquals(previousSearchTerm: SearchConfig, currentSearchTerm: SearchConfig): boolean {
    return (
      previousSearchTerm.game === currentSearchTerm.game &&
      previousSearchTerm.site === currentSearchTerm.site &&
      previousSearchTerm.partnerId === currentSearchTerm.partnerId
    );
  }

  public updateSearchConfig(searchConfig: SearchConfig) {
    const sanitizedSearchConfig = {
      game: searchConfig.game || null,
      site: searchConfig.site || null,
      partnerId: searchConfig.partnerId || null,
    };

    this.searchConfigSignal.set(sanitizedSearchConfig);
    this.updateConfigInUrl(sanitizedSearchConfig);
  }
}
