import { Injectable, inject, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Game, Site } from '../shapes/games.types';
import { Observable, firstValueFrom } from 'rxjs';
import { GamesDataService } from './games-data.service';
import { GamesSiteService } from './games-site.service';
import { DialogOrchestrationService } from './dialog-orchestration.service';

// TODO: This should eventually be abstracted further to a project wide BE service that it interacts with.
@Injectable({
  providedIn: 'root',
})
export class GamesFetchService {
  private readonly dialogOrchestrationService = inject(DialogOrchestrationService);
  private readonly httpClient = inject(HttpClient);
  private readonly gamesDataService = inject(GamesDataService);
  private readonly gamesSiteService = inject(GamesSiteService);

  private loadingSignal = signal(false);

  public readonly loading = this.loadingSignal.asReadonly();

  public updateLoadingSignal() {
    this.loadingSignal.update(currentValue => !currentValue);
  }

  public async updateIsVisibleTargetSite(siteName: string, visible: boolean) {
    const url = `/api/games/sites/${siteName}/visibility:set`;
    const update = { visible };

    this.updateLoadingSignal();
    try {
      await this.updateWithFirstValueFrom(url, update);

      void this.gamesSiteService.updateIsVisibleAllSitesSignal(siteName, visible);
    } finally {
      this.updateLoadingSignal();
    }
  }

  public async fetchAllSitesAndGames() {
    this.updateLoadingSignal();

    try {
      const games$ = await this.fetchAllGames();
      await this.fetchAllSites();
      const games = await firstValueFrom(games$);
      games.forEach((game: Game) => this.setGameAssetsURL(game));
      void this.gamesDataService.setAllGamesSignal(games);
    } finally {
      this.updateLoadingSignal();
    }
  }

  public async updateGameOptimistically<T>(game: Game, update: Partial<Game>, beCall: () => Promise<T>): Promise<T> {
    try {
      this.gamesDataService.updateTargetGameInAllGamesSignal({ ...game, ...update });
      return await beCall();
    } catch (error) {
      // Restore original game value
      this.gamesDataService.updateTargetGameInAllGamesSignal(game);
      throw error;
    }
  }

  public async updateGameActiveState(game: Game, gameState: 'active' | 'disable' | 'shutdown') {
    const active = gameState === 'active';
    const gracefulShutdown = gameState === 'shutdown';
    const force = gameState === 'disable';

    const update = { active, gracefulShutdown };
    const url = `/api/games/sites/${game.site}/games/${game.gameName}/config/active?force=${force}`;

    return await this.updateGameOptimistically(game, update, () => this.updateWithFirstValueFrom(url, update));
  }

  public async updateIsGameVisible(game: Game, visible: boolean) {
    const url = `/api/games/sites/${game.site}/games/${game.gameName}/config/visible`;

    const update = { visible };

    return await this.updateGameOptimistically(game, update, () => this.updateWithFirstValueFrom(url, update));
  }

  public async updateIsGameNew(game: Game, isNew: boolean) {
    const url = `/api/games/sites/${game.site}/games/${game.gameName}/config/new`;

    const update = { isNew };

    return await this.updateGameOptimistically(game, update, () => this.updateWithFirstValueFrom(url, update));
  }

  public async updateGamePriority(game: Game, priority: number) {
    const url = `/api/games/sites/${game.site}/games/${game.gameName}/config/priority`;

    const update = { priority };

    return await this.updateGameOptimistically(game, update, () => this.updateWithFirstValueFrom(url, update));
  }

  public async updateIsSiteVisible(siteName: string, visible: boolean) {
    if (
      !(await this.dialogOrchestrationService.showConfirmDialog('Do you want to change the visibility of this site?'))
    ) {
      return;
    }

    await this.dialogOrchestrationService.showBlockingDialog(async () => {
      await this.updateIsVisibleTargetSite(siteName, visible);
    });
  }

  private async updateWithFirstValueFrom<T>(url: string, update: Partial<Game>): Promise<T> {
    return await firstValueFrom<T>(this.httpClient.post<T>(url, update));
  }

  // TODO: Might need to be called at an earlier time to update these immediately.
  private async setGameAssetsURL(game: Game): Promise<void> {
    game.assetsUrl = new URL(
      `https://gitlab.com/zig-services/game-assets/-/raw/master/${game.gameName}/assets.zip?inline=false`
    );
  }

  private async fetchAllSites(): Promise<void> {
    this.updateLoadingSignal();

    try {
      const allSitesResponse = await firstValueFrom(this.httpClient.get<Site[]>('/api/games/sites'));
      this.gamesSiteService.setAllSitesSignal(allSitesResponse);
    } finally {
      this.updateLoadingSignal();
    }
  }

  private async fetchAllGames(): Promise<Observable<Game[]>> {
    this.updateLoadingSignal();

    try {
      return this.httpClient.get<Game[]>('/api/games');
    } finally {
      this.updateLoadingSignal();
    }
  }
}
