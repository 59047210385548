import { Injectable, inject } from '@angular/core';
import type { Game } from '../shapes/games.types';
import { GamesDataService } from './games-data.service';
import { GamesFetchService } from './games-fetch.service';
import { DialogOrchestrationService } from './dialog-orchestration.service';

@Injectable({
  providedIn: 'root',
})
export class GamesSettingsService {
  private readonly gamesDataService = inject(GamesDataService);
  private readonly gamesFetchService = inject(GamesFetchService);
  private readonly dialogOrchestrationService = inject(DialogOrchestrationService);

  // * This fn() can handle setting prio for 0, 1, or N entries
  private async updateGamesPriorities(games: Game[], priority: number): Promise<void> {
    if (
      !(await this.dialogOrchestrationService.showConfirmDialog(
        `Do you really want to apply these changes to ${games.length} game/s?`
      ))
    ) {
      return;
    }

    await this.dialogOrchestrationService.showBlockingDialog(async () => {
      for (const game of games) {
        await this.gamesFetchService.updateGamePriority(game, priority);
      }
    });
  }

  public async setGamePriosToZero(): Promise<void> {
    const filteredGamesExclusiveOfZeroPrio = this.gamesDataService.filteredGames().filter(game => game.priority > 0);
    await this.updateGamesPriorities(filteredGamesExclusiveOfZeroPrio, 0);
  }

  public async syncAllSiteGamePrios(currentGame: Game, priority: number): Promise<void> {
    const allGames = this.gamesDataService.allGames();
    const gamesToUpdate = allGames.filter(game => game.gameName === currentGame.gameName);
    await this.updateGamesPriorities(gamesToUpdate, priority);
  }

  // * Does not respect search filters
  public async setHighestPriorityGame(currentGame: Game): Promise<void> {
    const highestPrioGame = this.gamesDataService.highestPrioGame();
    const newHighestPriority = highestPrioGame === undefined ? currentGame.priority + 1 : highestPrioGame.priority + 1;
    await this.syncAllSiteGamePrios(currentGame, newHighestPriority);
  }
}
