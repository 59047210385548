// TODO: Update this to a POJO as const (https://www.youtube.com/watch?v=6M9aZzm-kEc)
export enum GameState {
  active = 'Active',
  shutdown = 'Clean Shutdown',
  disable = 'Disabled',
}

export type GameSettingsActions = {
  state?: GameState;
  isVisible?: boolean;
  isNew?: boolean;
  priority?: number;
};
